import { Button, Modal, Table } from "antd";
import { FC, useState } from "react";
import { connect } from "react-redux";
import EyeOutlined from "@ant-design/icons/EyeOutlined";
import { ViewAttachmentPropType } from "../../utils/Communication.util";
import DocumentViewerComponent from "../../../common/DocumentViewer/DocumentViewer.component";
import { DownloadFile } from "../../../Document/MyDocument/index.util";
import { CloudDownloadOutlined } from "@ant-design/icons";
const ViewAttachmentComponent: FC<ViewAttachmentPropType> = ({
  communication,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        className="p-0"
        icon={<EyeOutlined />}
        type="text"
        onClick={() => setIsModalVisible(true)}
      ></Button>

      <Modal
        className="fixed-modal"
        centered
        width={600}
        title="Attachment"
        visible={isModalVisible}
        onCancel={handleOk}
        footer={[<></>]}
      >
        <div className="row">
          <div className="col-md-12">
            <Table
              pagination={false}
              dataSource={communication.documents}
              columns={[
                {
                  title: "No",
                  key: "no",
                  render: (value, record, index) => index + 1,
                },
                {
                  title: "Name",
                  key: "name",
                  render: (value, record) => record.name,
                },
                {
                  title: "Action",
                  key: "action",
                  render: (value, record) => (
                    <>
                      <Button
                        type="link"
                        loading={loading}
                        icon={<CloudDownloadOutlined />}
                        onClick={() => DownloadFile(record, setLoading)}
                      />

                      <DocumentViewerComponent document={record} />
                    </>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

/**
 * Map State to Props
 *
 * @param state
 */
const mapStateToProps = (state: any) => ({
  communications: state.communication.fetchAll,
});

/**
 * Map Dispatch to Props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: any) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAttachmentComponent);
