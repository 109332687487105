export type ApiCallState<T> = {
  error: any;
  payload: T;
  isPending: boolean;
  isSuccessful: boolean;
};

export const resetApiCallState = (payload: any) => ({
  error: null,
  payload: payload,
  isPending: false,
  isSuccessful: false,
});

export type PagedData<T> = {
  page_size: number;
  rows: T;
  count: number;
  current_page: number;
};

export const parseQuery = (action: { payload: any }) => {
  let query = "";
  if (action.payload) {
    const keys = Object.keys(action.payload);
    query = keys
      .filter(
        (key) =>
          action.payload[key] !== null && action.payload[key] !== undefined
      ) // Remove null & undefined
      .map((key) => `${key}=${encodeURIComponent(action.payload[key])}`) // Encode values
      .join("&");
  }
  return query;
};
